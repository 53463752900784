
import { defineComponent, onMounted, ref, nextTick } from "vue";

import banner from "@/components/common/Swiper.vue";
import list from "@/components/common/list.vue";

import newsItem from "@/components/common/NewsItem.vue";
import TopicItem from "@/components/common/TopicItem.vue";
import ClassItem from "@/components/common/ClassItem.vue";
import productCard from "@/components/common/productCard.vue";

import MenuTab from "@/components/common/MenuTab.vue";
import Toast from "@/components/UI/Toast/";
//ts
import { productMap } from "@/hooks/useProduct";
//  utils
import api from "@/api/axios";
import Swiper from "swiper";

export default defineComponent({
  name: "Index",
  components: {
    banner,
    list,
    newsItem,
    TopicItem,
    ClassItem,
    MenuTab,
    productCard,
  },

  setup() {
    onMounted(() => {
      nextTick(() => {
        const swiper = new Swiper("#hot-swiper", {
          slidesPerView: 2,
          initialSlide: 0,
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          observeParents: true, //修改swiper的父元素时，自动初始化swiper
          on: {
            observerUpdate: function () {
              this.slideTo(0);
            },
          },
        });

        const swiper2 = new Swiper("#hot-swiper2", {
          slidesPerView: 2,
          initialSlide: 0,
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          observeParents: true, //修改swiper的父元素时，自动初始化swiper
          on: {
            observerUpdate: function () {
              this.slideTo(0);
            },
          },
        });
      });
    });

    function filterFunc(data: any) {
      if (data.fenlei == 228) {
        const teacherNames =
          (data.teacherNames && data.teacherNames.split(",")) || [];
        const teacherIds =
          (data.teacherIds && data.teacherIds.split(",")) || [];
        teacherNames.pop();
        teacherIds.pop();
        data.teacherNames = teacherNames;
        data.teacherIds = teacherIds;
      }
      return data;
    }

    const list = ref(null);
    const hotSaleList = ref(null);
    const hotSaleList2 = ref(null);
    api.get("/M").then((res): void => {
      list.value = res.data.obj.listimg;
      hotSaleList.value = res.data.obj.hotSaleList;
      hotSaleList2.value = res.data.obj.hotSaleList2;
    });
    //  切换列表菜单
    type Menu = "新闻资讯" | "知识交流" | "热销产品";
    const menu: Menu[] = [ "新闻资讯", "知识交流", "热销产品"];
    const currentMenuItem = ref(menu[0]);
    function selectMenu(item: Menu) {
      currentMenuItem.value = item;
    }

    return {
      list,
      hotSaleList,
      hotSaleList2,
      menu,
      selectMenu,
      currentMenuItem,
      productMap,
      filterFunc,
    };
  },
});
